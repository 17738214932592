.main {
  width: 100%;
  padding-top: 73px;
}

.account-pages {
  height: 100%;
}

.dashboardBackImg {
  height: 50%;
  width: 1556px;
  position: absolute;
  top: 0;
  margin: 25px 20px 0;
}

.dashRow {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.patientListBtnWrap {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 4px;
  padding: 0px 0px;
}

.patientListBtn {
  width: 100%;
  padding: 3px 15px 3px 15px;
  border: solid 2px #000000;
  background-color: #e9e9e9;
  font-family: Arial;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  // color: #19ce27;
  color: #0f0f0f;
}