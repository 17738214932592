.shopHeaderWrap {
  background-color: #fff;
  width: 100%;
  height: 70px;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 0px 50px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  // position: fixed;
  // z-index: 10000;
}

.shopMegaMenuWrap {
  width: 100%;
  margin: 0;
  display: flex;
  align-items: center;
  padding: 5px 50px 5px;
  z-index: 100;
}

.headerText {
  color: #202020;
  cursor: pointer;
}

.menutext {
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.subtext {
  color: #707070;
  font-size: 16px;
  cursor: pointer;
}

.linkClick {
  color: #fff;
  :hover {
    color: aqua;
  }
}

.titleLine {
  width: 100%;
  border: 1px solid #b4b4b4;
}

ul {
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 0;
  list-style: none;
}

nav {
  font-size: 0;
  position: relative;
}

nav > ul > li {
  display: inline-block;
  font-size: 14px;
  padding: 0 15px;
  position: relative;
}

nav > ul > li > a {
  color: #fff;
  display: block;
  padding: 20px 0;
  border-bottom: 3px solid transparent;
  transition: all 0.3s ease;
}
nav > ul > li:hover > a {
  color: #444;
  border-bottom: 3px solid #444;
}

.mega-menu {
  background: #fafafa;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  position: fixed;
  left: 0;
  width: 100%;
  padding-bottom: 20px;
  z-index: 100;
}
.mega-menu h3 {
  color: #444;
}

.mega-menu .container {
  display: flex;
}
.mega-menu .item {
  flex-grow: 1;
  margin: 0 10px;
}
.mega-menu .item img {
  width: 100%;
}
.mega-menu a {
  border-bottom: 1px solid #ddd;
  color: #4ea3d8;
  display: block;
  padding: 10px 0;
}
.mega-menu a:hover {
  color: #2d6a91;
}

.dropdown {
  position: static;
  width: 100%;
  padding: 0px;
}

.dropdown:hover .mega-menu {
  visibility: visible;
  opacity: 1;
}

.sidebar {
  // width: 300px !important;
  // transition: all 0.35s linear;
}

.hideSidebar {
  width: 300px !important;
  transition: all 0.35s linear;
  transform: translateX(-100%);
}

.nav-link {
  font-size: 15px;
  font-weight: normal;
  color: #000;
  padding: 0px;
}

#basic-nav-dropdown {
  font-size: 18px;
  color: #fff;
}
#basic-nav-dropdown::after {
  display: none;
}
#seeMoreLink {
  color: #607d8b;
}

#seeMoreLink:hover {
  color: black;
  font-weight: 400;
}
.dropdown-menu {
  padding: 1em;
}

.dropdown-item:hover {
  outline: none;
  box-shadow: none;
  background: none;
}
.dropdown-item span:hover {
  color: black;
}
.dropdown-item-text span:hover {
  color: black;
}

.dropdown-item {
  padding-left: 0em;
}

.dropdown-item-text {
  padding: 2px 0px;
}

.dropdown-header {
  font-weight: bold;
  padding-left: 0em;
  font-size: 16px;
  color: #000;
}

.customized-menu {
  width: 100vw;
}

.css-177ic5c {
  max-width: 100vw !important;
}
// .navdropcostom > .dropdown-menu {
//   border: none;
//   box-shadow: none;
//   background: none;
// }

.MuiGrid-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  max-width: 100vw !important;
  left: 0 !important;
}
.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover {
  -webkit-text-decoration: none;
  text-decoration: none;
  background-color: none !important;
}
@media only screen and (min-width: 600px) {
  .eventsNav {
    width: max-content;
    padding-bottom: 1em;
  }
}
@media print {
  #leftSidebarwrap {
    display: none !important;
  }
}
