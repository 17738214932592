@media print {
  #printLayoutHide,
  #printSide,
  #printLayoutHideBtn {
    display: none !important;
  }
  #printBody {
    width: 100% !important;
  }
}
