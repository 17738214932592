//
// apexcharts.scss
//
.apex-charts {
  text {
    font-family: $font-family-base !important;
    fill: $gray-500;
  }
  .apexcharts-canvas {
    margin: 0 auto;
  }
}

.apexcharts-tooltip-title,
.apexcharts-tooltip-text {
  font-family: $font-family-base !important;
}

.apexcharts-legend-series {
  font-weight: $font-weight-medium;
}

.apexcharts-gridline {
  pointer-events: none;
  stroke: $apex-grid-color;
}

.apexcharts-legend-text {
  color: $gray-600 !important;
  font-family: $font-family-base !important;
  font-size: 13px !important;
  font-weight: 600 !important;
}

.apexcharts-pie-label {
  fill: $white !important;
}

.apexcharts-yaxis,
.apexcharts-xaxis {
  text {
    font-family: $font-family-base !important;
    fill: $gray-500;
  }
}

#treemap-chart {
  &.apex-charts text {
    fill: $white !important;
  }
}
