.modalWrap {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2000;
}

.popUpmodalWrap {
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000;
}

.modalOverlay {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 1;
}

.modalCard {
  z-index: 1000 !important;
  padding: 36px 103px;
  border-radius: 20px;
  justify-content: center;
}
.modalPWCard {
  z-index: 1000 !important;
  padding: 36px 38px;
  width: 960px;
  border-radius: 20px;
  justify-content: center;
}

.lineuplabel {
  font-size: 14px !important;
  line-height: 1.43 !important;
  color: #b3b3b3 !important;
}

#mastercloseBtn {
  background-color: #fff !important;
  border: 1px solid #c9c9c9 !important;
  color: #c9c9c9 !important;
}

#masterAccesBtn {
  background-color: #fff !important;
  border: 1px solid #ff6900 !important;
  color: #ff6900 !important;
}

.modalHoverDiv {
  text-align: start;
  font-weight: bold;
  padding: 10px;
}

.modalHoverDiv:hover {
  background-color: #b3b3b3;
  border-radius: 8px;
  color: #fff;
  text-align: start;
  cursor: pointer;
  padding: 10px;
}

.changePWInput {
  font-size: 19px;
  padding: 16px 25px;
  border: 1px solid #dcdcdc;
  border-radius: 8px;
  ::placeholder {
    color: #898989;
  }
}

.findPwInput {
  height: 62px;
  font-size: 17px;
  ::placeholder {
    color: #898989;
  }
}
