.KR-000- {
  height: 100vh;
  background-color: #fff;
}

.backgroundimg {
  width: 100%;
  position: absolute;
  height: 50%;
}

.loginheaderline {
  width: 100%;
  height: 106px;
  opacity: 0.5;
  background-color: #000;
  position: fixed;
}

.log-in {
  font-size: 200px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #272727;
  position: fixed;
  bottom: -60px;
  right: -20px;
  opacity: 0.05;
}

.LoginWrap {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.logincard {
  width: 740px;
  height: 520px;
  border-radius: 25px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.lo368 {
  position: absolute;
  width: 115.9px;
  height: 46.9px;
  margin: 30.5px 0 0 40px;
}

.loginCardInputWrap {
  width: 100%;
  padding: 0 115px;
}

.titleWrap {
  margin-top: 31px;
}

.title {
  width: 62px;
  height: 29px;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
}

.subtitle {
  margin: 20px 0 59px 0;
}

.Already-have-an-account-Login-below {
  width: 347px;
  height: 24px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
}

.IdWrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 24px 0;
}
.idspan {
  width: 52px;
  height: 24px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
}

.input325 {
  width: 410px;
  height: 55px;
  padding: 16px 20px 15px 20px;
  border-radius: 10px;
  border: solid 1px #acacac;
  background-color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
}

.PwWrap {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 63px 0;
}

.pwspan {
  height: 24px;
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
}

.inputpw325 {
  width: 410px;
  height: 55px;
  padding: 16px 20px 15px 20px;
  border-radius: 10px;
  border: solid 1px #acacac;
  background-color: #fff;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
}

.btn326 {
  width: 510px;
  height: 55px;
  margin: 0 0 31px 0;
  border: none;
  border-radius: 28px;
  background-color: #3fa01c;
  span {
    width: 52px;
    height: 24px;
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
  }
}

.forgotpw {
  width: 150px;
  height: 20px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #656565;
}

.line393 {
  width: 150px;
  height: 1px;
  margin: 3px 0 0 0;
  background-color: #707070;
}

.cardtitleline {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dashText {
  font-size: 200px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #272727;
  position: fixed;
  bottom: -60px;
  right: 0;
  opacity: 0.05;
}

.insideText {
  font-size: 200px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #272727;
  position: absolute;
  bottom: 70px;
  right: -10px;
  opacity: 0.05;
}

.insideIrrText {
  font-size: 200px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #272727;
  position: absolute;
  bottom: 70px;
  right: -20px;
  opacity: 0.05;
}

@media screen and (max-width: 720px) {
  .log-in {
    display: none;
  }
  .logincard {
    width: 100%;
    height: 520px;
    border-radius: 25px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .loginCardInputWrap {
    width: 100%;
    padding: 0 50px;
  }

  .input325 {
    width: 300px;
    height: 55px;
    padding: 16px 20px 15px 20px;
    border-radius: 10px;
    border: solid 1px #acacac;
    background-color: #fff;
    font-family: Roboto;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #272727;
  }
  .inputpw325 {
    width: 300px;
    height: 55px;
    padding: 16px 20px 15px 20px;
    border-radius: 10px;
    border: solid 1px #acacac;
    background-color: #fff;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #272727;
  }
  .btn326 {
    width: 80%;
    height: 55px;
    margin: 0 0 31px 0;
    border: none;
    border-radius: 28px;
    background-color: #3fa01c;
    span {
      width: 52px;
      height: 24px;
      font-family: Roboto;
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #fff;
    }
  }
}
@media screen and (max-width: 500px) {
  .loginCardInputWrap {
    width: 100%;
    padding: 0 10px;
  }
  .dashText {
    display: none;
  }
}

.loginInput {
  border: 1px solid #a7a7a7;
  width: 100%;
  border-radius: 6px;
  padding: 13px 16px;
  color: #56c79c;
}

.loginInput::placeholder {
  color: #a7a7a7;
}
.loginInputPw {
  border: 1px solid #a7a7a7;
  width: calc(100% - 111px);
  border-radius: 6px;
  padding: 13px 16px;
  outline: none;
}

.loginInputPw::placeholder {
  color: #a7a7a7;
}

.loginInputPwspan {
  border: 1px solid #a7a7a7;
  width: 100%;
  border-radius: 6px;
  padding: 13px 16px;
  color: #56c79c;
  outline: none;
}

.loginInputPwspan::placeholder {
  color: #56c79c;
}

.loginEmailInput {
  margin-bottom: 39px;
}

.loginEmailInput_cpw {
  margin-bottom: 25px;
}

.checkBox {
  appearance: none;
  border: 2px solid #4dc091;
  border-radius: 3px;
  width: 18px;
  height: 18px;

  &:checked {
    border: 2px solid #4dc091;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='#4DC091' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.707 7.293a1 1 0 0 0-1.414 1.414l2 2a1 1 0 0 0 1.414 0l4-4a1 1 0 0 0-1.414-1.414L7 8.586 5.707 7.293z'/%3e%3c/svg%3e");
    background-size: 100% 100%;
    background-position: 50%;
    background-repeat: no-repeat;
  }
}

.loginInputPw:focus {
  outline: none;
  border: 1px solid #4dc091;
}
.loginInputPw:focus ~ .loginInputPwspan {
  outline: none;
  border: 1px solid #4dc091;
}

.loginInput:focus {
  outline: none;
  border: 1px solid #4dc091;
}
