.bloodSugarWrap {
  height: 100%;
}

.bloodSugarRow {
  display: flex;
  justify-content: center;
  margin: 70px 0 0 0 !important;
}

.bloodCard {
  border-radius: 0 !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.bloodSugarDown {
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #19ce27;
  margin-bottom: 20px;
}

.newBloodCardWrap {
  padding: 63px 86px;
}

.bloodName {
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #19ce27;
  margin-bottom: 36px;
}

.bloodSubTitle {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
}

.bloodBtnWrap {
  display: flex;
  justify-content: center;
  margin-top: 36px;
  margin-bottom: 120px;
  padding: 0 158px;
}

.bloodBtn {
  width: 100%;
  padding: 16px 0 15px;
  border: solid 1px #19ce27;
  background-color: #fff;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #19ce27;
}

.bloodSugarDataRow {
  padding: 0 20px;
}

.bloodSugarDataRowDiv {
  display: flex;
  position: relative;
  top: 20px;
  z-index: 1;
}

.dateToDate {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
  padding: 6px 18px;
}

.bloodDataCard {
  padding: 37px 0 83px 34px;
}

.bloodDataCardRow {
  display: flex;
  padding: 14px 108px 0 183px;
  align-items: flex-end;
}

.bloodDataCardDiv {
  display: flex;
  flex-direction: column;
  margin-right: 227px;
}

.bloodDataCardDivLast {
  display: flex;
  flex-direction: column;
  margin-right: 145px;
}

.bloodDataCardDivNum {
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #272727;
  margin-bottom: 14px;
}

.bloodDataCardDivSpan {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #272727;
  margin-bottom: 8px;
}

.bloodDataCardDivBlood {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #272727;
}

.bloodSubDataCard {
  padding: 37px 0 37px 34px;
}

.recordChart {
  width: 100%;
  height: 203px;
}
