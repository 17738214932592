.wrap {
  width: 100%;
  height: 100%;
  padding-left: 26px;
}

.divsWrap {
  background-color: #fff;
}

.icons {
  width: 22.9px;
  height: 22.9px;
  fill: #19ce27;
  margin-right: 15px;
}

.iconsTitle {
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #19ce27;
}

.bloodDatesRowDiv {
  display: flex;
}

.bloodDates {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
  padding: 6px 18px;
  z-index: 10;
}

.titleLineWrap {
  display: flex;
  align-items: center;
  margin-bottom: 21px;
}

.snapTitleLineWrap {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
}

.titleLine {
  width: 100%;
  border: 1px solid #19ce27;
}

.agpWrap {
  margin-top: 18px !important;
}

.bloodSugarTotalCard {
  margin-bottom: 24px;
  border-radius: 0 !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.bloodSugarTotalWrap {
  padding: 38px 119px 42px;
}

.bloodSugarTotalTitle {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #272727;
  margin-bottom: 44px;
}
.tableTitle {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
}
.tableSubTitle {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
}
.tableSub {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #bcbcbc;
  margin-left: 8px;
}

.tableText {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
}

.tableSubText {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #bcbcbc;
  margin-top: 40px;
}

.bloodSugarSubTotalCard {
  margin-bottom: 80px;
  border-radius: 0 !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.bloodSugarSubTotalWrap {
  padding: 23px 88px 22px 112px;
}

.subTotalDiv {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
}

.subTotalDivLast {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.tableSubdLTitle {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
  margin-left: 8px;
}

.bloodSugarCards {
  border-radius: 0 !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
  position: relative;
  top: -20px;
}

.topDate {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
  padding: 6px 18px;
  z-index: 10;
  margin-left: 16px;
}

.bloodPatternWrap {
  padding: 98.5px 158px 62.5px 153px;
  height: 500px;
}

.dailyLogfirstRow {
  margin-top: 37px !important;
}

.dailyLogRow {
  margin-top: 54px !important;
}

.snapDate {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
  padding: 6px 18px;
  z-index: 10;
}

.snapRow {
  position: relative;
  top: -20px;
}

.snapCard {
  border-radius: 0 !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.tableTextRight {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: right;
  color: #272727;
}

.deviceInput {
  border-radius: 10px !important;
  border: solid 1px #acacac !important;
  background-color: #fff !important;
  padding: 12px 19.8px 14px 20px !important;
  font-size: 20px !important;
  font-weight: normal !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal !important;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
}

.device {
  width: 127px;
  height: 201px;
  margin-right: 45px;
}

.dispalyColumn {
  display: flex;
  flex-direction: column;
}
.monthIcons {
  width: 24px;
  height: 24px;
  z-index: 10;
  cursor: pointer;
}

.monthTopDate {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  background-color: #fff;
  border-radius: 20px;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
  padding: 6px 18px;
  z-index: 10;
}

.makeStyles-container-23 {
  margin-top: 30px;
}

.Cell-cell-39 {
  padding: 0;
  box-sizing: border-box;
  text-align: center;
  user-select: none;
  border-right: none !important;
  border-bottom: none;
}

.tableHeader {
  border: none !important;
}

.tableBorder {
  border: solid 1px #707070 !important;
  height: 100%;
}

.tableBodyRight {
  border-right: dotted 1px #707070 !important;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #272727;
  padding-top: 14% !important;
}

.tableBodyLast {
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #272727;
  padding-top: 14% !important;
}

.printTotalwrap {
  display: none !important;
}

@media print {
  #agpwrap {
    padding-top: 50px;
  }
  #printBtn {
    display: none !important;
  }
  .printHIdeBtn {
    display: none !important;
  }
  .printTotalwrap {
    display: block !important;
  }
  .divsWrap {
    padding: 0px 20px !important;
  }
  .agpchartWrap {
    flex-direction: column;
  }
  .agpchartWrapcard {
    min-width: 100% !important;
    width: 100% !important;
  }
}
