.listAddDisplay {
  display: flex;
  justify-content: center;
}
.listAddCard {
  border: 1px solid #d6d6d6 !important;
  // border-radius: 0 !important;
  // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  background-color: #fff;
}

.threeDotsWrap {
  text-align: end;
  margin-top: 10px;
}

.threeDots {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.listAddRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.listAddName {
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
  margin: 0 !important;
}

.listAddInput {
  width: 410px !important;
  height: 55px !important;
  padding: 10px 20px 10px 20px !important;
  border-radius: 10px !important;
  border: solid 1px #acacac !important;
  background-color: #fff !important;
  font-size: 20px !important;
}

.listAddBtn {
  width: 554px;
  height: 55px;
  margin-top: 33px;
  padding: 17px 0 14px;
  border-radius: 28px;
  background-color: #3fa01c;
  border: none;
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.listAddMapWrap {
  width: 410px;
  height: 410px;
  overflow: hidden;
  border: 1px solid #acacac;
  border-radius: 10px;
}
.listAddMapRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.listAddMapName {
  font-family: Roboto;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
  margin: 15px 0 0 0 !important;
}

.profileName {
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #19ce27;
  margin-bottom: 90px;
}

.profileInCard {
  padding: 15px 96px 63px;
}

.birthWrap {
  margin-bottom: 10px;
}

.birthTitle {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
}

.birthDayWrap {
  margin-bottom: 36px;
}

.birthDay {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
}

.profileInCardRow {
  margin: 0 !important;
}

.profileInCardName {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
  margin-bottom: 10px;
}

.profileInCardEmailInput {
  padding: 12px 20px 14px 20px !important;
  border-radius: 10px !important;
  border: solid 1px #acacac !important;
  background-color: #fff !important;
  margin-bottom: 36px;
}

.profileInCardInput {
  padding: 12px 20px 14px 20px !important;
  border-radius: 10px !important;
  border: solid 1px #acacac !important;
  background-color: #fff !important;
}

.profileBtn {
  position: relative;
  top: 36px;
  text-align: center;
  width: 100%;
}

.profileBtn326 {
  width: 100%;
  padding: 16px 0 15px;
  border: solid 1px #19ce27;
  background-color: #fff;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #19ce27;
}

.settingWrap {
  padding: 63px 96px;
}

.myHosWrap {
  padding: 63px 78px;
}

.myHosName {
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #19ce27;
  margin-bottom: 16px;
}

.myHosTitle {
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #272727;
  margin-bottom: 66px;
}

.myHosInputRow {
  margin: 0 !important;
  padding: 0 65px;
}

.myHosSubTitle {
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #272727;
  margin-bottom: 44px;
}

.myDeviceName {
  font-size: 26px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #19ce27;
  margin-bottom: 36px;
}

.deviceInWrap {
  padding: 63px 0 92px 0;
}

.deviceBtn {
  position: relative;
  top: 36px;
  text-align: center;
  width: 100%;
  padding: 0 158px;
}

#page-header-notifications-dropdown {
  box-shadow: none !important;
}
.pencilText {
  font-size: 20px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left !important;
  color: #272727 !important;
  display: flex !important;
  align-items: center;
  padding: 18.5px 50px 13.5px 28.4px !important;
}

.trashText {
  font-size: 20px;
  font-weight: 500 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left !important;
  color: #ff6969 !important;
  display: flex !important;
  align-items: center;
  padding: 18.5px 50px 13.5px 28.4px !important;
}

.pencilIcon {
  width: 20px;
  height: 20px;
  fill: #000;
  margin-right: 15px;
}

.trashIcon {
  width: 20px;
  height: 24px;
  fill: #ff6969;
  margin-right: 15px;
}

.dropdown-menu {
  width: none;
  min-width: 0 !important;
  border: none !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
}

#headerDropdown {
  min-width: 330px !important;
}
